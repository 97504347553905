$(document).ready(function() {

  if ($('#js-canvas').length) {

    var canvasWidth = 256;
    var canvasHeight = 256;
    var brushWidth = 3;

    if ($(window).width() >= 768) {
      canvasWidth = 512;
      canvasHeight = 512;
      brushWidth = 6;
    }

    var canvas = new fabric.Canvas('js-canvas', {
      height: canvasHeight,
      width: canvasWidth,
      isDrawingMode: true
    });

    canvas.freeDrawingBrush.width = brushWidth;

    var canvasHistory = new CanvasHistory();

    canvasHistory.add({data: JSON.stringify(canvas)});

    $('#js-undo-button').on('click', function(e) {
      e.preventDefault();

      canvasHistory.setUndoMode(true);

      var previous = canvasHistory.undo();
      canvas.loadFromJSON(previous.data);
      canvas.renderAll();

      canvasHistory.setUndoMode(false);
    });

    canvas.on('object:added', function(e) {
      var undoMode = canvasHistory.getUndoMode();

      if (undoMode === false) {
        var currentCanvasState = {
          data: JSON.stringify(canvas)
        };

        canvasHistory.add(currentCanvasState);
      }
    });

    $('#js-submit').on('submit', function(e) {
      e.preventDefault();

      $('#js-submit-button').prop('disabled', true);

      var json = JSON.stringify(canvas);

      $(this).find('input[name="json"]').val(json);
      $(this).find('input[name="width"]').val(canvas.width);
      $(this).find('input[name="height"]').val(canvas.height);

      $(this).off('submit');
      $(this).submit();
    });

  }

});


function CanvasHistory() {
  this.history = [];
  this.undoMode = false;
}

CanvasHistory.prototype.undo = function() {
  if (this.history.length > 1) {
    this.history.splice(-1, 1);
  }

  var index = this.history.length - 1;

  return this.history[index];
};

CanvasHistory.prototype.add = function(obj) {
  this.history.push(obj);
};

CanvasHistory.prototype.getUndoMode = function() {
  return this.undoMode;
};

CanvasHistory.prototype.setUndoMode = function(value) {
  this.undoMode = value;
};
